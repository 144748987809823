import {Component, ContentChild, ElementRef, OnInit, OnDestroy} from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {

  @ContentChild('footer', {static: false}) footer: ElementRef;

  constructor() { }

  ngOnInit() {
    window.addEventListener('keyboardWillShow', () => {
      document.body.style.paddingBottom = `8rem`;
    });

    window.addEventListener('keyboardWillHide', () => {
      document.body.style.paddingBottom = '0px';
    });
  }

  ngOnDestroy() {
    window.removeAllListeners();
  }
}
